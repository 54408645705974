import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import OrderUpdateItem from "./OrderUpdateItem";

export default function OrderCart(props) {
  const [order, setOrder] = useState(props.order);

  useEffect(() => {
    const updateOrder = (e) => {
      setOrder(e.detail.order);
    };

    window.addEventListener("updateorder", updateOrder);

    return () => window.removeEventListener("updateorder", updateOrder);
  });

  return (
    <>
      <div className="px-4 py-2">
        <OrderItems
          items={order.items}
          updateOrderPath={props.updateOrderPath}
        />
      </div>
      <div className="p-4 pt-2 flex flex-row justify-between">
        <div>
          <div className="text-gray-500 mr-3 text-sm">Total</div>
          <div className="text-gray-900 font-bold">{order.total}</div>
        </div>
        <div className="self-end">
          <a
            href={props.checkoutPath}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
          >
            {props.checkoutTitle}
            <svg
              className="h-4 w-4 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}

function OrderItems({ updateOrderPath, items }) {
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="pr-2 py-2 border-b border-gray-200 text-left text-sm w-full">
            Item
          </th>
          <th className="pl-2 py-2 border-b border-gray-200 text-left text-sm">
            Qty.
          </th>
        </tr>
      </thead>
      <tbody>
        {items.length == 0 && (
          <tr>
            <td className="pr-2 py-2 text-center text-sm" colSpan="2">
              (Empty)
            </td>
          </tr>
        )}
        {items.map((item) => (
          <tr key={item.id}>
            <td className="pr-2 py-2 text-left text-sm">
              <span className="font-medium">{item.sku}</span>
              <br />
              <span className="text-gray-500">{item.name}</span>
            </td>
            <td className="pl-2 py-2 text-left text-sm">
              <OrderUpdateItem
                quantity={item.quantity}
                productId={item.productId}
                updateEndpoint={updateOrderPath}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function OrderItemCount({ count }) {
  if (count == 0) {
    return "empty";
  } else if (count == 1) {
    return "1 item";
  } else {
    return `${count} items`;
  }
}
