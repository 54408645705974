import React from 'react'
import ReactDOM from "react-dom"

function readFile(path) {
  var reader = new FileReader();

  reader.readAsText(path, "UTF-8");

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      resolve(e.target.result);
    }
    reader.onerror = reject;
  })
}

export default class ImportProducts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null,
      sku_col: "",
      price_col: "",
      cols: [],
    }
  }

  chooseFile(e) {
    const file = e.target.files[0];

    this.setState({
      file,
      sku_col: "",
      price: "",
    })

    readFile(file)
      .then(result => {
        const lines = result.split('\n')
        const cols = lines[0].split(",")

        this.setState({ cols })
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { file, cols } = this.state

    const setSku = sku_col => this.setState({ sku_col })
    const setPrice = price_col => this.setState({ price_col })

    return (
      <div>
        <label htmlFor="products" className="mt-4 block text-sm font-medium leading-5 text-gray-700">
          Upload a list of products (.csv)
        </label>
        <input name="products" type="file" onChange={e => this.chooseFile(e)} className="mt-2 inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150" required />
        <input type="hidden" required name="price_col" defaultValue={this.state.price_col} onChange={e => setPrice(e.target.value) } />
        {file && (
          <>
            <label htmlFor="sku_col" className="mt-2 block text-sm font-medium leading-5 text-gray-700">
              Select the SKU column
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select required name="sku_col" defaultValue={this.state.sku_col} onChange={e => setSku(e.target.value )} id="sku_col" className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            {cols.map(col => (<option value={col} key={col}>{col}</option>))}
              </select>
            </div>
            <label htmlFor="price_col" className="mt-2 block text-sm font-medium leading-5 text-gray-700">
              Select the price column
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <select required name="price_col" defaultValue={this.state.price_col} onChange={e => setPrice(e.target.value )} id="price_col" className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            {cols.map(col => (<option value={col} key={col}>{col}</option>))}
              </select>
            </div>
          </>
        )}
      </div>
    )
  }
}
