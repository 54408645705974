import React, { useState } from 'react'
import ReactDOM from 'react-dom'

export default function CustomerTiers({ discount_tiers, product_fields }) {
  const tiers = Object.keys(discount_tiers).map(k => ({ tier: k, ...discount_tiers[k] }))

  const [discountTiers, setDiscountTiers] = useState(
    tiers.length ? tiers : [{ tier: "", field: "" }]
  )

  function without(removeIdx) {
    return discountTiers.filter((x, i) => i != removeIdx)
  }

  return [...discountTiers.map(({ tier, field }, i) => (
    <CustomerTier
      tier={tier}
      field={field}
      key={String(tier+i)}
      productFields={product_fields}
      isLast={i == discountTiers.length - 1}
      remove={() => setDiscountTiers(without(i))}
    />
  )), (
    <tr key="000">
      <td></td>
      <td></td>
      <td className="py-2">
        <a onClick={() => setDiscountTiers([...discountTiers, { tier: "", field: "" }])} className="rounded-full cursor-pointer">
          <svg fill="currentColor" viewBox="0 0 20 20" className="text-blue-500 h-6 w-6"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd"></path></svg>
        </a>
      </td>
    </tr>
  )]
}

function CustomerTier(props) {
  const { isLast, productFields, remove } = props

  const [tier, setTier] = useState(props.tier)
  const [field, setField] = useState(props.field)
  const cell_class = isLast ? "px-6 whitespace-no-wrap text-sm leading-4" : "px-6 whitespace-no-wrap border-b border-gray-200 text-sm leading-5"

  return (
    <tr>
      <td className={cell_class+' py-2'}>
        <input type="text" tabIndex={tier == "" ? 0: -1} onChange={e => setTier(e.target.value)} required className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-4" defaultValue={tier} />
      </td>
      <td className={cell_class+' py-2'}>
        <div className="max-w-xs rounded-md">
          <select name={`vendor[discount_tiers][${tier}][field]`} onChange={e => setField(e.target.value)} value={field} className="text-gray-600 block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-4 py-2">
            {productFields.map(pf => (
              <option key={pf.field_key} value={pf.field_key}>{pf.display_name || pf.field_key}</option>
            ))}
          </select>
        </div>
      </td>
      <td>
        <a onClick={remove} className="rounded-full cursor-pointer">
          <svg fill="currentColor" viewBox="0 0 20 20" className="text-blue-500 h-6 w-6">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd"></path>
          </svg>
        </a>
      </td>
    </tr>
  )
}
