// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "alpinejs";
import "./stylesheets.css";
import { updateOrder } from "./events";

function imageData(e) {
  const path = e.target.files[0];
  var reader = new FileReader();

  reader.readAsDataURL(path);

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = reject;
  });
}

function parseParams(url) {
  if (url.indexOf("?") < 0) {
    return {};
  }

  const queryString = url.split("?")[1];

  return queryString.split("&").reduce((acc, pair) => {
    const [key, value] = pair.split("=");
    acc[key] = value;

    return acc;
  }, {});
}

function reloadWithParams(newParams) {
  const existingParams = parseParams(window.location.href);
  const url = window.location.href.split("?")[0];

  const params = { ...existingParams, ...newParams };
  const queryString = Object.keys(params)
    .filter((key) => params[key] != "")
    .map((key) => `${key}=${params[key]}`)
    .join("&");

  console.log(Turbolinks.supported);
  if (queryString.length) {
    Turbolinks.visit(url + "?" + queryString);
  } else {
    Turbolinks.visit(url);
  }
}

function number_to_currency(number, options) {
  try {
    var options   = options || {};
    var precision = options["precision"] || 2;
    var unit      = options["unit"] || "$";
    var separator = precision > 0 ? options["separator"] || "." : "";
    var delimiter = options["delimiter"] || ",";
  
    var parts = parseFloat(number).toFixed(precision).split('.');
    return unit + number_with_delimiter(parts[0], delimiter) + separator + parts[1].toString();
  } catch(e) {
    return number;
  }
}

window.imageData = imageData;
window.updateOrder = updateOrder;
window.reloadWithParams = reloadWithParams;
window.number_to_currency = number_to_currency;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
