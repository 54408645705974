import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { put } from "./request";

function find(list, fn) {
  const filtered = list.filter(fn);
  return filtered[0];
}

export default function OrderUpdateItem(props) {
  const updateEndpoint = props.updateEndpoint;

  const productId = props.productId;
  const productQuantity = props.quantity || 0;

  const [quantity, setQuantity] = useState(productQuantity);

  useEffect(() => {
    const updateOrder = (e) => {
      const item = find(
        e.detail.order.items,
        (item) => item.productId == props.productId
      );
      setQuantity(item ? item.quantity : 0);
    };

    window.addEventListener("updateorder", updateOrder);

    return () => window.removeEventListener("updateorder", updateOrder);
  });

  const updateQuantity = (_quantity) => {
    const quantity = Math.max(0, _quantity);

    put(updateEndpoint, {
      items: { [productId]: quantity },
    }).then((result) => {
      if (props.refresh) {
        Turbolinks.visit(window.location);
      } else if (!result.error) {
        window.updateOrder(result);
      }
    });
  };

  const onChange = (e) => {
    updateQuantity(e.target.value);
  };

  const stepDown = (e) => {
    e.preventDefault();
    updateQuantity(quantity - 1);
  };

  const stepUp = (e) => {
    e.preventDefault();
    updateQuantity(quantity + 1);
  };

  return (
    <div className="flex flex-row -my-1 items-center">
      <button
        type="submit"
        onClick={stepDown}
        className={`inline-flex mr-1 w-5 h-5 ${
          quantity <= 0 ? "text-gray-200" : "text-gray-400 hover:text-gray-700"
        } rounded-full focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150`}
        disabled={quantity <= 0}
      >
        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <input
        type="number"
        name={`items[${productId}]`}
        min={0}
        onChange={onChange}
        value={quantity || 0}
        className="item-quantity w-12 rounded-md form-input block rounded-none transition ease-in-out duration-150 sm:text-sm sm:leading-4 px-0 text-center"
      />
      <button
        type="submit"
        onClick={stepUp}
        className="inline-flex ml-1 text-gray-400 hover:text-gray-700 rounded-full focus:outline-none focus:border-blue-700 focus:shadow-outline-blue  transition ease-in-out duration-150"
      >
        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
}
