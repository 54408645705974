import React, { useState } from 'react'
import ReactDOM from 'react-dom'

export default function ProductField({ cell_class, field, display_name, hidden_from_view, customer_visible, sort_position }) {
  function _visibility() {
    if (hidden_from_view) {
      return 'hidden'
    } else if (customer_visible) {
      return 'customer'
    } else {
      return 'private'
    }
  }

  const [visibility, setVisibility] = useState(_visibility())

  const display = visibility === 'customer'
  const hidden = visibility === 'hidden'

  if (display) {
    cell_class += ' bg-blue-100'
  } else if (hidden) {
    cell_class += ' text-gray-300'
  }

  return (
    <>
      <td className={cell_class+' py-2'}>{field}</td>
      <td className={cell_class+' py-2'}>
        <input type="text" name={`vendor[product_fields][][display_name]`} required className={"appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-4" + (display ? 'text-gray-400' : '')} readOnly={hidden} defaultValue={display_name || field} />
        <input type="hidden" name={`vendor[product_fields][][field_key]`} value={field} />
        <input type="hidden" name={`vendor[product_fields][][hidden_from_view]`} value={hidden} />
        <input type="hidden" name={`vendor[product_fields][][customer_visible]`} value={display} />
      </td>
      <td className={cell_class+' py-2'}>
        <div className="max-w-xs rounded-md">
          <select onChange={e => setVisibility(e.target.value)} value={visibility} className="text-gray-600 block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-4 py-2">
            <option value="customer">Shop</option>
            <option value="private">Admin</option>
            <option value="hidden">Hidden</option>
          </select>
        </div>
      </td>
      <td className={cell_class+' py-2'}>
        <input type="number" name={`vendor[product_fields][][sort_position]`} required className={"block w-12 px-2 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-4" + (display ? 'text-gray-400' : '')} readOnly={hidden} defaultValue={sort_position || 0} />
      </td>
    </>
  )
}
